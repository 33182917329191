import axios from 'axios';

export const request = async (method, url, params = {}) => {
    try {
        const options = {
            method: method,
            url: process.env.GATSBY_API_URL + url,
            data: params,
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_TOKEN}`
            }
        }

        const response = await axios(options);
        return response.data;
    } catch (error) {
        if (error.response) {
            /**
             *  The request was made and the server responded with a status code
             *  that falls out of the range of 2xx
             **/
            console.error(error.response.data);
            throw { //eslint-disable-line
                message: typeof error.response.data.error.message !== 'string' ? error.response.data.error.message : 'Something went wrong! The Drivosity Support Team has been notified and will respond to your request.',
                status: error.response.status | 500
            }
        } else if (error.request) {
            /**
             *  The request was made but no response was received
             *  `error.request` is an instance of XMLHttpRequest in the browser and an instance of
             *  http.ClientRequest in node.js
             **/
            console.error(error.request);
            throw { //eslint-disable-line
                message: 'Something went wrong!',
                status: 500
            }
        } else {
            /**
             *  Something happened in setting up the request that triggered an Error
             **/
            console.error('Error', error.message);
            throw { //eslint-disable-line
                message: 'Something went wrong!',
                status: 500
            }
        }
    }
}
